<template>
  <div class="flex h-full">
    <div
      class="flex h-full w-full items-center justify-center md:block md:w-1/2 lg:w-2/5"
    >
      <div
        class="mx-4 flex w-full items-center justify-center rounded-md bg-white py-8 shadow-md sm:mx-32 md:mx-0 md:h-full md:rounded-none"
      >
        <div class="w-full max-w-md px-4 sm:w-2/3">
          <router-link
            class="flex items-center gap-2 py-2 hover:underline"
            to="sign-in"
          >
            <ArrowLeftIcon class="h-5 w-5" /><span>{{
              t("links.to_sign_in")
            }}</span>
          </router-link>
          <h1>{{ t("headings.forgot") }}</h1>
          <p class="py-4">{{ t("text.forgot") }}</p>
          <custom-form
            :formElements="formElements"
            formStyle="block"
            @submitForm="sendPasswordReset"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ArrowLeftIcon } from "@heroicons/vue/24/outline";
import { ref } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import CustomForm from "../components/form/CustomForm.vue";
export default {
  components: { ArrowLeftIcon, CustomForm },
  setup() {
    const store = useStore();
    const { t } = useI18n();

    const formElements = ref([
      {
        class: "col-span-12",
        element: "input",
        id: "email",
        label: "email",
        rules: ["isRequired"],
        type: "text",
        value: "",
        variable: "email",
      },
      {
        class: "col-span-12",
        element: "button",
        id: "submit-button",
        label: "send",
        textVariant: "light",
        type: "submit",
        variant: "indigo",
      },
    ]);

    return {
      formElements,
      sendPasswordReset: (data) =>
        store.dispatch("authentication/sendPasswordReset", data.email),
      t,
    };
  },
};
</script>
